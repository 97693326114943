// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-entry-js": () => import("/Users/thomase/Projects/Staccx/what-is.finance/web/src/templates/Entry.js" /* webpackChunkName: "component---src-templates-entry-js" */),
  "component---src-pages-404-js": () => import("/Users/thomase/Projects/Staccx/what-is.finance/web/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/Users/thomase/Projects/Staccx/what-is.finance/web/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-list-js": () => import("/Users/thomase/Projects/Staccx/what-is.finance/web/src/pages/list.js" /* webpackChunkName: "component---src-pages-list-js" */),
  "component---src-pages-suggest-words-js": () => import("/Users/thomase/Projects/Staccx/what-is.finance/web/src/pages/suggestWords.js" /* webpackChunkName: "component---src-pages-suggest-words-js" */)
}

