module.exports = [{
      plugin: require('/Users/thomase/Projects/Staccx/what-is.finance/web/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-156564308-1","head":true},
    },{
      plugin: require('/Users/thomase/Projects/Staccx/what-is.finance/web/node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/thomase/Projects/Staccx/what-is.finance/web/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
